import {
  ACTION_INPUTS_DEPENDED_UPON,
  DEPENDENCIES_FOR_ACTION,
  KeysForAction,
} from "./dependencies";
import { IAction, IActionData, IBaseActionData } from "@netcero/netcero-core-api-client";
import { MDRUtilities } from "../mdrs/mdrs.utilities";
import { pick } from "lodash";

export class ActionsUtilities {
  /**
   * This method determines whether the input in question should be shown, based on the current state of the dialog.
   * @param inputInQuestion The input for which the state should be determined
   * @param value The current state of the dialog to base decision on
   */
  public static shouldInputBeShown(inputInQuestion: KeysForAction, value: IActionData) {
    return MDRUtilities.shouldInputBeShown<KeysForAction>(
      inputInQuestion,
      value,
      DEPENDENCIES_FOR_ACTION,
    );
  }

  /**
   * This method determines whether the input in question is depended upon by another input.
   * @param inputInQuestion The input for which to check whether other inputs depend on it
   */
  public static isInputDependedUponByAnother(inputInQuestion: KeysForAction) {
    return ACTION_INPUTS_DEPENDED_UPON.includes(inputInQuestion);
  }

  /**
   * This method determines whether the input in question is conditional.
   * @param inputInQuestion The input for which to check whether it is conditional
   */
  public static isInputConditional(inputInQuestion: KeysForAction) {
    return DEPENDENCIES_FOR_ACTION[inputInQuestion] !== undefined;
  }

  /**
   * This method converts a action to action data.
   * @param action The action to convert
   */
  public static convertActionToActionData(action: IAction): IBaseActionData {
    return pick(action, [
      "associatedTopics",
      "general",
      "e1",
      "e2",
      "e3",
      "e4",
      "e5",
      "e1And4",
      "s",
      "materialImpactIds",
      "financialEffectIds",
      "policyIds",
    ]);
  }
}
