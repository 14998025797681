import { useQuery } from "@tanstack/react-query";
import { CoreApiService } from "../core-api/core-api.service";
import { useCallback } from "react";
import { IAction } from "@netcero/netcero-core-api-client";

export const getActionsQueryKey = (organizationId: string, recordingPeriodId: string) => [
  "actions",
  organizationId,
  recordingPeriodId,
];

export const useActionsQuery = (
  organizationId: string,
  recordingPeriodId: string,
  dataEntryObjectId: string,
) => {
  const filterActionsByDEO = useCallback(
    // no need to check other properties as they are contained in the initial query
    (actions: IAction[]) => actions.filter((p) => p.dataEntryObjectId === dataEntryObjectId),
    [dataEntryObjectId],
  );

  return useQuery({
    queryKey: getActionsQueryKey(organizationId, recordingPeriodId),
    queryFn: () =>
      CoreApiService.ActionsApi.getActions(organizationId, recordingPeriodId)
        .then((req) => req())
        .then((res) => res.data.actions),
    select: filterActionsByDEO,
  });
};
