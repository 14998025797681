import {
  IBaseActionData,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
} from "@netcero/netcero-core-api-client";

export type IActionEditDialogData = IBaseActionData;

export type IActionEditDialogSectionKey = "e1" | "e2" | "e3" | "e4" | "e5" | "s";

export class ActionsAppUtilities {
  public static getIdentifierKeyForTopic(
    topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  ): IActionEditDialogSectionKey {
    switch (topicIdentifier) {
      case "esrs_e1":
        return "e1";
      case "esrs_e2":
        return "e2";
      case "esrs_e3":
        return "e3";
      case "esrs_e4":
        return "e4";
      case "esrs_e5":
        return "e5";
      case "esrs_s1":
      case "esrs_s2":
      case "esrs_s3":
      case "esrs_s4":
        return "s";
    }
  }
}
