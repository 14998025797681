import { Box, FormControlLabel, InputAdornment, Switch, TextField, Tooltip } from "@mui/material";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import {
  IMDRBooleanInput,
  IMDREnumInput,
  IMDRInput,
  IMDRNumberInput,
  IMDRTextInput,
  IMDRYearInput,
} from "../mdr-input.types";
import { ReactElement, useMemo, useCallback } from "react";
import { UseFormStateReturn } from "react-hook-form/dist/types";
import { ControllerFieldState, ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { RegisterOptions } from "react-hook-form/dist/types/validator";
import { HierarchicalDropdownEnum } from "./hierarchical-dropdown.component";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

const NON_FULL_WIDTH_WIDTH = 650;

export interface IMDRControlInputProps<T extends FieldValues> extends IMDRInputProps {
  propertyPath: Path<T>;
  control: Control<T>;
  getRuleTranslation: (property: Path<T>, rule: "required") => string;
}

export interface IMDRInputProps {
  label: string;
  isDependedUponByAnother: boolean;
  isConditional: boolean;
  disabled: boolean;
}

export interface IControlRenderProps<T extends FieldValues> {
  field: ControllerRenderProps<T, Path<T>>;
  fieldState: ControllerFieldState;
  formState: UseFormStateReturn<T>;
}

export type IMDRControlledInputProps<
  DataType extends FieldValues,
  InputMetaDataType,
> = IMDRInputProps &
  IControlRenderProps<DataType> & {
    valueMetaData: InputMetaDataType;
  };

function TextInput<T extends FieldValues>({
  valueMetaData,
  label,
  isConditional,
  field,
  fieldState: { error },
  disabled,
}: IMDRControlledInputProps<T, IMDRTextInput>) {
  const multilineProps = useMemo(
    () =>
      valueMetaData.multiline
        ? {
            multiline: true,
            minRows: 3,
            maxRows: 5,
          }
        : {},
    [valueMetaData.multiline],
  );

  return (
    <>
      <Box pl={valueMetaData.isChild || isConditional ? 6 : 0}>
        <TextField
          {...field}
          label={
            <Tooltip title={label}>
              <span style={{ fontWeight: valueMetaData.isParent ? "bold" : undefined }}>
                {label}
              </span>
            </Tooltip>
          }
          variant="outlined"
          fullWidth={valueMetaData.multiline}
          error={!!error}
          helperText={error ? error.message : null}
          disabled={disabled}
          sx={{
            width: !valueMetaData.multiline ? NON_FULL_WIDTH_WIDTH : undefined,
            maxWidth: "100%",
          }}
          {...multilineProps}
        />
      </Box>
      {valueMetaData.isLastChild && <Box />}
    </>
  );
}

function BooleanInput<T extends FieldValues>({
  label,
  field: { onChange, value, ref },
  disabled,
}: IMDRControlledInputProps<T, IMDRBooleanInput>) {
  return (
    <Box>
      <FormControlLabel
        control={<Switch checked={value} onChange={onChange} ref={ref} disabled={disabled} />}
        label={label}
      />
    </Box>
  );
}

function EnumInput<T extends FieldValues>({
  label,
  field: { onChange, value },
  valueMetaData,
  translateEnumValue,
  disabled,
}: IMDRControlledInputProps<T, IMDREnumInput> & {
  translateEnumValue: (value: string) => string;
}) {
  return (
    <Box maxWidth={valueMetaData.mode === "single" ? NON_FULL_WIDTH_WIDTH : undefined}>
      <HierarchicalDropdownEnum
        type={valueMetaData.mode}
        // The `as never` is so typescript will not complain about the mode being "single" and the emptyOption being a boolean
        emptyOption={
          (valueMetaData.mode === "single" ? !valueMetaData.required : undefined) as never
        }
        label={label}
        translateValueLabel={translateEnumValue}
        values={valueMetaData.values}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </Box>
  );
}

function NumberInput<T extends FieldValues>({
  valueMetaData,
  label,
  isConditional,
  field,
  fieldState: { error },
}: IMDRControlledInputProps<T, IMDRNumberInput>) {
  const numberProps = useMemo(
    () => ({
      type: "number",
      inputProps: {
        step: valueMetaData.precision !== undefined ? Math.pow(10, -valueMetaData.precision) : 0,
        min: valueMetaData.min,
        max: valueMetaData.max,
      },
    }),
    [valueMetaData.min, valueMetaData.max, valueMetaData.precision],
  );

  const endAdornment = useMemo(() => {
    switch (valueMetaData.numberType) {
      case "currency":
        return <InputAdornment position="end">&euro;</InputAdornment>;
      default:
        return null;
    }
  }, [valueMetaData.numberType]);

  const handleChange = useCallback(
    (value: string) => {
      // Emit empty
      if (value === "") {
        field.onChange(null);
        return;
      }
      const number = +value;
      // Handle Precision
      if (valueMetaData.precision !== undefined) {
        const factor = Math.pow(10, valueMetaData.precision);
        field.onChange(+(Math.floor(number * factor) / factor).toFixed(valueMetaData.precision));
        return;
      }
      // Normal
      field.onChange(number);
    },
    [field, valueMetaData.precision],
  );

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        pl={valueMetaData.isChild || isConditional ? 6 : 0}
      >
        <TextField
          {...field}
          onChange={(evt) => handleChange(evt.currentTarget.value)}
          label={
            <Tooltip title={label}>
              <span style={{ fontWeight: valueMetaData.isParent ? "bold" : undefined }}>
                {label}
              </span>
            </Tooltip>
          }
          variant="outlined"
          error={!!error}
          helperText={error ? error.message : null}
          {...numberProps}
          InputProps={{ endAdornment }}
          sx={{ maxWidth: NON_FULL_WIDTH_WIDTH }}
        />
      </Box>
      {valueMetaData.isLastChild && <Box />}
    </>
  );
}

function YearInput<T extends FieldValues>({
  valueMetaData,
  label,
  field,
  fieldState: { error },
}: IMDRControlledInputProps<T, IMDRYearInput>) {
  return (
    <DatePicker
      label={label}
      views={["year"]}
      value={field.value ? DateTime.local(field.value, 1, 1) : null}
      onChange={(value) => field.onChange(value?.year ?? null)}
      slotProps={{
        field: {
          clearable: !valueMetaData.required,
          onClear: () => field.onChange(null),
        },
        textField: {
          helperText: error?.message,
        },
      }}
      sx={{ maxWidth: NON_FULL_WIDTH_WIDTH }}
    />
  );
}

export const MdrInputComponentFactory = {
  createComponent<T extends FieldValues>(
    valueMetaData: IMDRInput,
    { control, propertyPath, getRuleTranslation, ...props }: IMDRControlInputProps<T>,
    translateEnumValue: (value: string) => string,
  ) {
    const rules: Omit<
      RegisterOptions<T, Path<T>>,
      "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
    > = {};

    // Required Rule
    if (valueMetaData.required) {
      if (valueMetaData.type === "boolean" || valueMetaData.type === "text") {
        rules.required = getRuleTranslation(propertyPath, "required");
      } else if (valueMetaData.type === "enum") {
        rules.validate = (value: string[]) => {
          return value.length > 0 || getRuleTranslation(propertyPath, "required");
        };
      }
    }

    return (
      <Controller
        control={control}
        name={propertyPath}
        rules={rules}
        render={MdrInputComponentFactory.createInputComponentForControl(
          valueMetaData,
          props,
          translateEnumValue,
        )}
      />
    );
  },
  createInputComponentForControl<T extends FieldValues>(
    valueMetaData: IMDRInput,
    props: IMDRInputProps,
    translateEnumValue: (value: string) => string,
  ) {
    return (fieldData: IControlRenderProps<T>): ReactElement => {
      switch (valueMetaData.type) {
        case "text":
          return TextInput<T>({ valueMetaData, ...props, ...fieldData });
        case "boolean":
          return BooleanInput<T>({ valueMetaData, ...props, ...fieldData });
        case "enum":
          return EnumInput<T>({ valueMetaData, ...props, ...fieldData, translateEnumValue });
        case "number":
          return NumberInput<T>({ valueMetaData, ...props, ...fieldData });
        case "year":
          return YearInput<T>({ valueMetaData, ...props, ...fieldData });
      }
    };
  },
};
