import {
  IActionDataE1,
  IActionDataE1And4,
  IActionDataE2,
  IActionDataE3,
  IActionDataE4,
  IActionDataE5,
  IActionDataGeneral,
  IActionDataS,
  IAdaptionSolutionTypeEnum,
  IDecarbonisationLeverTypeEnum,
  ILayerInMitigationHierarchyForBiodiversityAndEcosystemsEnum,
  ILayerInMitigationHierarchyForPollutionEnum,
  ILayerInMitigationHierarchyForWaterAndMarineResourcesEnum,
  IStatusOfKeyActionEnum,
  ITimeHorizonUnderWhichKeyActionIsToBeCompletedEnum,
} from "@netcero/netcero-core-api-client";
import { IMDRInput } from "../minimum-disclosure-requirements-common/mdr-input.types";
import { ISpecialTopicSectionInputIdentifier } from "../minimum-disclosure-requirements-common/components/mdr-topic-section.component";

type GENERAL_KEY = keyof IActionDataGeneral;
export const GENERAL_ORDER: (GENERAL_KEY | ISpecialTopicSectionInputIdentifier)[] = [
  "name",
  "iros",
  "policies",
  "disclosureOfKeyActionExplanatory",
  "statusOfKeyAction",
  "timeHorizonUnderWhichKeyActionIsToBeCompleted",
  "yearWhenKeyActionIsToBeCompleted",
  "disclosureOfExpectedOutcomesOfKeyActionAndHowItsImplementationContributesToAchievementOfPolicyObjectivesAndTargetsExplanatory",
  "descriptionOfScopeOfKeyActionExplanatory",
  "keyActionIsTakenToProvideForAndCooperateInOrSupportProvisionOfRemedyForThoseHarmedByActualMaterialImpacts",
  "disclosureOfQuantitativeAndQualitativeInformationRegardingProgressOfActionsOrActionPlansDisclosedInPriorPeriodsExplanatory",
  "disclosureOfSignificantOperationalExpendituresOpexAndOrCapitalExpendituresCapexRequiredForImplementationOfActionPlanExplanatory",
  "descriptionOfTypeOfCurrentAndFutureFinancialAndOtherResourcesAllocatedToActionPlanExplanatory",
  "abilityToImplementActionOrActionPlanDependsOnSpecificPreconditions",
  "explanationOfHowCurrentFinancialResourcesRelateToMostRelevantAmountsPresentedInFinancialStatementsExplanatory",
  "financialResourcesAllocatedToActionPlanOpEx",
  "financialResourcesAllocatedToActionPlanCapEx",
];
export const GENERAL: { [key in GENERAL_KEY]: IMDRInput } = {
  name: {
    type: "text",
    initialValue: "",
    required: true,
    multiline: false,
  },
  disclosureOfKeyActionExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  statusOfKeyAction: {
    type: "enum",
    mode: "single",
    initialValue: null,
    values: Object.values(IStatusOfKeyActionEnum),
  },
  timeHorizonUnderWhichKeyActionIsToBeCompleted: {
    type: "enum",
    mode: "single",
    initialValue: null,
    values: Object.values(ITimeHorizonUnderWhichKeyActionIsToBeCompletedEnum),
  },
  yearWhenKeyActionIsToBeCompleted: {
    type: "year",
    initialValue: null,
  },
  disclosureOfExpectedOutcomesOfKeyActionAndHowItsImplementationContributesToAchievementOfPolicyObjectivesAndTargetsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  descriptionOfScopeOfKeyActionExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  keyActionIsTakenToProvideForAndCooperateInOrSupportProvisionOfRemedyForThoseHarmedByActualMaterialImpacts:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfQuantitativeAndQualitativeInformationRegardingProgressOfActionsOrActionPlansDisclosedInPriorPeriodsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  disclosureOfSignificantOperationalExpendituresOpexAndOrCapitalExpendituresCapexRequiredForImplementationOfActionPlanExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  descriptionOfTypeOfCurrentAndFutureFinancialAndOtherResourcesAllocatedToActionPlanExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  abilityToImplementActionOrActionPlanDependsOnSpecificPreconditions: {
    type: "boolean",
    initialValue: false,
  },
  explanationOfHowCurrentFinancialResourcesRelateToMostRelevantAmountsPresentedInFinancialStatementsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  financialResourcesAllocatedToActionPlanOpEx: {
    type: "number",
    numberType: "currency",
    initialValue: null,
    precision: 2,
  },
  financialResourcesAllocatedToActionPlanCapEx: {
    type: "number",
    numberType: "currency",
    initialValue: null,
    precision: 2,
  },
};

export type E1_KEY = keyof IActionDataE1;
export const E1_ORDER: E1_KEY[] = [
  "decarbonisationLeverType",
  "adaptionSolutionType",
  "achievedGHGEmissionReductions",
  "expectedGHGEmissionReductions",
  "explanationOfExtentToWhichAbilityToImplementActionDependsOnAvailabilityAndAllocationOfResourcesExplanatory",
  "explanationOfAnyPotentialDifferencesBetweenSignificantOpExAndCapExDisclosedUnderESRSE1AndKeyPerformanceIndicatorsDisclosedUnderCommissionDelegatedRegulationEU2021NO2178TextBlockExplanatory",
];
export const E1: { [key in keyof IActionDataE1]: IMDRInput } = {
  decarbonisationLeverType: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(IDecarbonisationLeverTypeEnum),
  },
  adaptionSolutionType: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(IAdaptionSolutionTypeEnum),
  },
  achievedGHGEmissionReductions: {
    type: "number",
    initialValue: null,
  },
  expectedGHGEmissionReductions: {
    type: "number",
    initialValue: null,
  },
  explanationOfExtentToWhichAbilityToImplementActionDependsOnAvailabilityAndAllocationOfResourcesExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  explanationOfAnyPotentialDifferencesBetweenSignificantOpExAndCapExDisclosedUnderESRSE1AndKeyPerformanceIndicatorsDisclosedUnderCommissionDelegatedRegulationEU2021NO2178TextBlockExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export type E2_KEY = keyof IActionDataE2;
export const E2_ORDER: E2_KEY[] = [
  "layerInMitigationHierarchyToWhichActionCanBeAllocatedToPollution",
];

export const E2: { [key in keyof IActionDataE2]: IMDRInput } = {
  layerInMitigationHierarchyToWhichActionCanBeAllocatedToPollution: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ILayerInMitigationHierarchyForPollutionEnum),
  },
};

export type E3_KEY = keyof IActionDataE3;
export const E3_ORDER: E3_KEY[] = [
  "layerInMitigationHierarchyToWhichActionCanBeAllocatedToWaterAndMarineResources",
  "actionRelatesToAreasAtWaterRisk",
  "actionRelatesToAreasOfHighwaterStress",
  "informationAboutSpecificCollectiveActionForWaterAndMarineResourcesExplanatory",
];

export const E3: { [key in keyof IActionDataE3]: IMDRInput } = {
  layerInMitigationHierarchyToWhichActionCanBeAllocatedToWaterAndMarineResources: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ILayerInMitigationHierarchyForWaterAndMarineResourcesEnum),
  },
  actionRelatesToAreasAtWaterRisk: {
    type: "boolean",
    initialValue: false,
  },
  actionRelatesToAreasOfHighwaterStress: {
    type: "boolean",
    initialValue: false,
  },
  informationAboutSpecificCollectiveActionForWaterAndMarineResourcesExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
};

export type E4_KEY = keyof IActionDataE4;
export const E4_ORDER: E4_KEY[] = [
  "layerInMitigationHierarchyToWhichActionCanBeAllocatedToBiodiversityAndEcosystems",
  "disclosureOfHowMitigationHierarchyHasBeenAppliedWithRegardToActionsBiodiversityAndEcosystemsExplanatory",
  "biodiversityOffsetsWereUsedInActionPlan",
  "disclosureOfAimOfBiodiversityOffsetAndKeyPerformanceIndicatorsUsedExplanatory",
  "financingEffectsDirectAndIndirectCostsOfBiodiversityOffsets",
  "descriptionOfBiodiversityOffsetsExplanatory",
  "localAndIndigenousKnowledgeAndNaturebasedSolutionsHaveBeenIncorporatedIntoBiodiversityAndEcosystemsrelatedAction",
  "descriptionOfHowLocalAndIndigenousKnowledgeAndNatureBasedSolutionsHaveBeenIncorporatedIntoBiodiversityAndEcosystemsrelatedActionExplanatory",
  "disclosureOfListOfKeyStakeholdersInvolvedInKeyActionAndHowTheyAreInvolvedKeyStakeholdersNegativelyOrPositivelyImpactedByKeyActionAndHowTheyAreImpactedExplanatory",
  "explanationOfNeedForAppropriateConsultationsAndNeedToRespectDecisionsOfAffectedCommunitiesExplanatory",
  "descriptionOfWhetherKeyActionMayInduceSignificantNegativeSustainabilityImpactsBiodiversityAndEcosystemsExplanatory",
  "keyActionOccurrenceBiodiversityAndEcosystems",
  "keyActionPlanIsCarriedOutOnlyByUndertakingUsingItsResourcesBiodiversityAndEcosystems",
  "keyActionPlanIsPartOfWiderInitiativeToWhichUndertakingSignificantlyContributesBiodiversityAndEcosystems",
  "disclosureOfAdditionalInformationAboutProjectItsSponsorsAndOtherParticipantsBiodiversityAndEcosystemsExplanatory",
  "descriptionOfHowKeyActionContributesToSystemwideChangeBiodiversityAndEcosystemsExplanatory",
];

export const E4: { [key in keyof IActionDataE4]: IMDRInput } = {
  layerInMitigationHierarchyToWhichActionCanBeAllocatedToBiodiversityAndEcosystems: {
    type: "enum",
    mode: "multiple",
    initialValue: [],
    values: Object.values(ILayerInMitigationHierarchyForBiodiversityAndEcosystemsEnum),
  },
  disclosureOfHowMitigationHierarchyHasBeenAppliedWithRegardToActionsBiodiversityAndEcosystemsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  biodiversityOffsetsWereUsedInActionPlan: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfAimOfBiodiversityOffsetAndKeyPerformanceIndicatorsUsedExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  financingEffectsDirectAndIndirectCostsOfBiodiversityOffsets: {
    type: "number",
    initialValue: null,
  },
  descriptionOfBiodiversityOffsetsExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
    isLastChild: true,
  },
  localAndIndigenousKnowledgeAndNaturebasedSolutionsHaveBeenIncorporatedIntoBiodiversityAndEcosystemsrelatedAction:
    {
      type: "boolean",
      initialValue: false,
    },
  descriptionOfHowLocalAndIndigenousKnowledgeAndNatureBasedSolutionsHaveBeenIncorporatedIntoBiodiversityAndEcosystemsrelatedActionExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
      isLastChild: true,
    },
  disclosureOfListOfKeyStakeholdersInvolvedInKeyActionAndHowTheyAreInvolvedKeyStakeholdersNegativelyOrPositivelyImpactedByKeyActionAndHowTheyAreImpactedExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  explanationOfNeedForAppropriateConsultationsAndNeedToRespectDecisionsOfAffectedCommunitiesExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  descriptionOfWhetherKeyActionMayInduceSignificantNegativeSustainabilityImpactsBiodiversityAndEcosystemsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  keyActionOccurrenceBiodiversityAndEcosystems: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  keyActionPlanIsCarriedOutOnlyByUndertakingUsingItsResourcesBiodiversityAndEcosystems: {
    type: "boolean",
    initialValue: false,
  },
  keyActionPlanIsPartOfWiderInitiativeToWhichUndertakingSignificantlyContributesBiodiversityAndEcosystems:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfAdditionalInformationAboutProjectItsSponsorsAndOtherParticipantsBiodiversityAndEcosystemsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
      isLastChild: true,
    },
  descriptionOfHowKeyActionContributesToSystemwideChangeBiodiversityAndEcosystemsExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
};

export type E5_KEY = keyof IActionDataE5;
export const E5_ORDER: E5_KEY[] = [
  "actionCoversHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWater",
  "disclosureOfHowActionCoversHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWaterExplanatory",
  "actionCoversHigherRatesOfUseOfSecondaryRawMaterialsRecyclates",
  "disclosureOfHowActionCoversHigherRatesOfUseOfSecondaryRawMaterialsRecyclatesExplanatory",
  "actionCoversApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecycling",
  "disclosureOfHowActionCoversApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecyclingExplanatory",
  "actionCoversApplicationOfCircularBusinessPractices",
  "disclosureOfHowActionCoversApplicationOfCircularBusinessPracticesExplanatory",
  "actionCoversActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChain",
  "disclosureOfHowActionCoversActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChainExplanatory",
  "actionCoversOptimisationOfWasteManagementInLineWithWasteHierarchy",
  "disclosureOfHowActionCoversOptimisationOfWasteManagementInLineWithWasteHierarchyExplanatory",
  "resourcesCoverHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWater",
  "disclosureOfHowResourcesCoverHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWaterExplanatory",
  "resourcesCoverHigherRatesOfUseOfSecondaryRawMaterialsRecyclates",
  "disclosureOfHowResourcesCoverHigherRatesOfUseOfSecondaryRawMaterialsRecyclatesExplanatory",
  "resourcesCoverApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecycling",
  "disclosureOfHowResourcesCoverApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecyclingExplanatory",
  "resourcesCoverApplicationOfCircularBusinessPractices",
  "disclosureOfHowResourcesCoverApplicationOfCircularBusinessPracticesExplanatory",
  "resourcesCoverActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChain",
  "disclosureOfHowResourcesCoverActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChainExplanatory",
  "resourcesCoverOptimisationOfWasteManagementInLineWithWasteHierarchy",
  "disclosureOfHowResourcesCoverOptimisationOfWasteManagementInLineWithWasteHierarchyExplanatory",
];

export const E5: { [key in keyof IActionDataE5]: IMDRInput } = {
  actionCoversHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWater: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowActionCoversHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWaterExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  actionCoversHigherRatesOfUseOfSecondaryRawMaterialsRecyclates: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowActionCoversHigherRatesOfUseOfSecondaryRawMaterialsRecyclatesExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  actionCoversApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecycling:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfHowActionCoversApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecyclingExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  actionCoversApplicationOfCircularBusinessPractices: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowActionCoversApplicationOfCircularBusinessPracticesExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  actionCoversActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChain: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowActionCoversActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChainExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  actionCoversOptimisationOfWasteManagementInLineWithWasteHierarchy: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowActionCoversOptimisationOfWasteManagementInLineWithWasteHierarchyExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  resourcesCoverHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWater: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowResourcesCoverHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWaterExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  resourcesCoverHigherRatesOfUseOfSecondaryRawMaterialsRecyclates: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowResourcesCoverHigherRatesOfUseOfSecondaryRawMaterialsRecyclatesExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  resourcesCoverApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecycling:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfHowResourcesCoverApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecyclingExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  resourcesCoverApplicationOfCircularBusinessPractices: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowResourcesCoverApplicationOfCircularBusinessPracticesExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
  resourcesCoverActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChain: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowResourcesCoverActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChainExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  resourcesCoverOptimisationOfWasteManagementInLineWithWasteHierarchy: {
    type: "boolean",
    initialValue: false,
  },
  disclosureOfHowResourcesCoverOptimisationOfWasteManagementInLineWithWasteHierarchyExplanatory: {
    type: "text",
    initialValue: "",
    multiline: true,
  },
};

export type S_VALUES_ONLY = keyof IActionDataS;
export const S_ORDER: S_VALUES_ONLY[] = [
  "actionToProvideOrEnableRemedyInRelationToActualMaterialImpactOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersHasBeenTaken",
  "disclosureOfHowActionToProvideOrEnableRemedyInRelationToActualMaterialImpactOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersHasBeenTakenExplanatory",
];

export const S: { [key in keyof IActionDataS]: IMDRInput } = {
  actionToProvideOrEnableRemedyInRelationToActualMaterialImpactOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersHasBeenTaken:
    {
      type: "boolean",
      initialValue: false,
    },
  disclosureOfHowActionToProvideOrEnableRemedyInRelationToActualMaterialImpactOnOwnWorkforceValueChainWorkersAffectedCommunitiesConsumersAndEndUsersHasBeenTakenExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export type E1And4_KEY = keyof IActionDataE1And4;
export const E1And4_ORDER: E1And4_KEY[] = [
  "explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToRelevantLineItemsOrNotesInFinancialStatementsExplanatory",
  "explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToKeyPerformanceIndicatorsRequiredUnderCommissionDelegatedRegulationEU2021NO2178Explanatory",
  "explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToCapExPlanRequiredByCommissionDelegatedRegulationEU2021NO2178Explanatory",
];

export const E1And4: Record<keyof IActionDataE1And4, IMDRInput> = {
  explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToRelevantLineItemsOrNotesInFinancialStatementsExplanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToKeyPerformanceIndicatorsRequiredUnderCommissionDelegatedRegulationEU2021NO2178Explanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
  explanationOfRelationshipOfSignificantCapExAndOpExRequiredToImplementActionsTakenOrPlannedToCapExPlanRequiredByCommissionDelegatedRegulationEU2021NO2178Explanatory:
    {
      type: "text",
      initialValue: "",
      multiline: true,
    },
};

export const ALL_SECTIONS_CONFIG = {
  general: GENERAL,
  e1: E1,
  e2: E2,
  e3: E3,
  e4: E4,
  e5: E5,
  s: S,
  e1and4: E1And4,
};

export const ALL_SECTIONS_ORDER = {
  general: GENERAL_ORDER,
  e1: E1_ORDER,
  e2: E2_ORDER,
  e3: E3_ORDER,
  e4: E4_ORDER,
  e5: E5_ORDER,
  s: S_ORDER,
  e1and4: E1And4_ORDER,
};
