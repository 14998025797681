import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import {
  IAction,
  IActionData,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useState } from "react";

import { useTranslation } from "react-i18next";
import { DataEntryObjectInputParameterValueDefinitionForAction } from "@netcero/netcero-common";
import { AddIcon, IconSize } from "../../common/constants/tabler-icon.constants";
import { ActionTableCell } from "./action-table-cell.component";
import {
  useCreateActionMutation,
  useDeleteActionMutation,
  useUpdateActionMutation,
} from "../actions.mutations";
import { ActionEditDialog } from "./action-edit.dialog";
import { ConfirmDialog } from "../../common/dialogs/variants/confirm.dialog";
import { useDialogState, useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import { useResetMutationsOnOpen } from "../../common/hooks/use-reset-mutations-on-open.hook";

const HOVER_BACKGROUND_COLOR = "#0000000A";

interface IActionsTableProps {
  actions: IAction[];
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  rootDataEntryObjectId: string;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForAction) => void;
  disabled?: boolean;
}

export const ActionsTable: FC<IActionsTableProps> = ({
  actions,
  topicIdentifier,
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  rootDataEntryObjectId,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_action_component");

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const createActionMutation = useCreateActionMutation();
  const updateActionMutation = useUpdateActionMutation();
  const deleteActionMutation = useDeleteActionMutation();

  const hasActions = actions && actions.length > 0;

  const {
    isOpen: isCreateOpen,
    openDialog: openCreateDialog,
    closeDialog: closeCreateDialog,
  } = useDialogStateWithoutData();
  useResetMutationsOnOpen(isCreateOpen, createActionMutation);

  const {
    isOpen: isEditOpen,
    openDialog: openEditDialog,
    closeDialog: closeEditDialog,
    data: actionData,
  } = useDialogState<IAction>();
  useResetMutationsOnOpen(isEditOpen, updateActionMutation);

  const {
    isOpen: isDeleteDialogOpen,
    openDialog: openDeleteDialogDialog,
    closeDialog: closeDeleteDialog,
    data: deleteActionId,
  } = useDialogState<string>();
  useResetMutationsOnOpen(isDeleteDialogOpen, deleteActionMutation);

  const handleCreate = useCallback(
    async (payload: IActionData | null) => {
      if (payload !== null) {
        await createActionMutation.mutateAsync({
          organizationId: organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          payload,
        });
      }
      closeCreateDialog();
    },
    [closeCreateDialog, createActionMutation, dataEntryObjectId, organizationId, recordingPeriodId],
  );

  const handleEdit = useCallback(
    async (payload: IActionData | null) => {
      if (payload !== null) {
        await updateActionMutation.mutateAsync({
          organizationId: organizationId,
          recordingPeriodId,
          dataEntryObjectId,
          actionId: actionData?.id ?? "",
          payload,
        });
      }
      closeEditDialog();
    },
    [
      actionData?.id,
      closeEditDialog,
      dataEntryObjectId,
      updateActionMutation,
      organizationId,
      recordingPeriodId,
    ],
  );

  const handleDelete = useCallback(
    async (actionId: string | null, confirm: boolean | undefined) => {
      if (!actionId || !confirm) {
        closeDeleteDialog();
        return;
      }
      await deleteActionMutation.mutateAsync({
        organizationId,
        recordingPeriodId,
        dataEntryObjectId,
        actionId,
      });
      closeDeleteDialog();
      closeEditDialog();
    },
    [
      closeDeleteDialog,
      closeEditDialog,
      dataEntryObjectId,
      deleteActionMutation,
      organizationId,
      recordingPeriodId,
    ],
  );

  return (
    <>
      {/* Dialogs */}
      <ActionEditDialog
        open={isCreateOpen}
        loading={createActionMutation.isPending}
        onClose={handleCreate}
        error={createActionMutation.error}
        disabled={createActionMutation.isPending}
        topicIdentifier={topicIdentifier}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        rootDataEntryObjectId={rootDataEntryObjectId}
      />
      <ActionEditDialog
        open={isEditOpen}
        loading={updateActionMutation.isPending}
        onClose={handleEdit}
        onDelete={(actionId) => openDeleteDialogDialog(actionId)}
        error={updateActionMutation.error}
        disabled={updateActionMutation.isPending}
        action={actionData}
        topicIdentifier={topicIdentifier}
        organizationId={organizationId}
        recordingPeriodId={recordingPeriodId}
        rootDataEntryObjectId={rootDataEntryObjectId}
      />
      <ConfirmDialog
        open={isDeleteDialogOpen}
        error={deleteActionMutation.error}
        loading={deleteActionMutation.isPending}
        disabled={deleteActionMutation.isPending}
        onClose={(confirm: boolean) => handleDelete(deleteActionId!, confirm)}
        title={t("confirm_dialog.title")}
        content={t("confirm_dialog.body")}
        confirmOverride={t("confirm_dialog.button_confirm")}
      />

      {/* Content */}
      <Box display="flex" flexDirection="column" gap={1} width="100%">
        {!hasActions && (
          <Box display="flex" alignItems="center" gap={2}>
            <Typography>{t("no_actions_defined")}</Typography>
            <Button
              variant="outlined"
              size="small"
              color="error"
              onClick={() => onChange(false)}
              disabled={disabled}
              sx={{ minWidth: 124 }}
            >
              {t("button_choose_no_actions_for_topic")}
            </Button>
          </Box>
        )}
        {/* Table */}
        {hasActions && (
          <Table size="small">
            <TableHead>
              <TableCell>{t("name")}</TableCell>
            </TableHead>
            <TableBody>
              {actions.map((action) => (
                <Tooltip key={action.id} title={t("tooltip_edit_action")} placement="right">
                  <TableRow
                    onClick={() => openEditDialog(action)}
                    sx={{
                      cursor: "pointer",
                      ":hover": { bgcolor: HOVER_BACKGROUND_COLOR },
                    }}
                  >
                    <ActionTableCell
                      action={action}
                      showTooltip={showTooltip}
                      setShowTooltip={setShowTooltip}
                    />
                  </TableRow>
                </Tooltip>
              ))}
            </TableBody>
          </Table>
        )}
        {/* Add Button */}
        <Tooltip title={t("tooltip_add_action")}>
          <span style={{ width: "min-content" }}>
            <IconButton size="small" onClick={() => openCreateDialog()} disabled={disabled}>
              <AddIcon size={IconSize.Medium} />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </>
  );
};
