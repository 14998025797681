import React from "react";
import { TableCell, Tooltip, Typography } from "@mui/material";
import { IAction } from "@netcero/netcero-core-api-client";
import { LineClampTypography } from "../../common/components/line-clamp-typography.component";

interface IActionTableCellProps {
  showTooltip: boolean;
  setShowTooltip: (showTooltip: boolean) => void;
  action: IAction;
}

export const ActionTableCell: React.FC<IActionTableCellProps> = ({
  showTooltip,
  setShowTooltip,
  action,
}) => {
  return (
    <Tooltip
      title={
        showTooltip ? (
          <Typography variant="inherit" sx={{ whiteSpace: "pre-wrap" }}>
            {action.general.name}
          </Typography>
        ) : null
      }
      placement="bottom-start"
      disableHoverListener={!showTooltip}
    >
      <TableCell>
        <LineClampTypography
          variant="inherit"
          maxLines={3}
          onOverflowChange={setShowTooltip}
          sx={{ whiteSpace: "pre-wrap" }}
        >
          {action.general.name}
        </LineClampTypography>
      </TableCell>
    </Tooltip>
  );
};
