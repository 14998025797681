import { FC } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { OrganizationUserPicker } from "../../user/components/organization-user-picker.component";
import { InfoIcon } from "../../common/constants/tabler-icon.constants";
import { OrganizationUsersComponent } from "../../user/components/organization-users.component";
import { useTranslation } from "react-i18next";
import { IInternalEffectBaseData } from "./types/internal-effect-base-data.type";
import { LinkedSourcesChipsListEditor } from "../../sources/components/linked-sources-chips-list-editor.component";

interface IIroDialogTopSectionInputsProps {
  organizationId: string;
  disabled: boolean | undefined;
}

export const IroDialogTopSectionInputs: FC<IIroDialogTopSectionInputsProps> = ({
  organizationId,
  disabled,
}) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");

  const { control } = useFormContext<IInternalEffectBaseData>();

  return (
    <Box display="flex" flexDirection="column" gap={4}>
      {/* Linked Users section */}
      <Box display="flex" gap={6}>
        {/* Responsible User */}
        <Box>
          <Typography variant="subtitle1">{t("label_responsible_user")}</Typography>
          <Box pt={0.25}>
            <Controller
              control={control}
              name="responsibleUserId"
              render={({ field }) => (
                <OrganizationUserPicker
                  value={field.value}
                  onChange={(user) => field.onChange(user?.id ?? null)}
                  organizationId={organizationId}
                  disabled={disabled}
                />
              )}
            />
          </Box>
        </Box>

        {/* Assigned Users */}
        <Box>
          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="subtitle1">{t("label_assigned_users")}</Typography>
            <Tooltip title={t("explanation_assigned_users")} placement="right">
              <InfoIcon />
            </Tooltip>
          </Box>
          <Box pt={0.75}>
            <Controller
              control={control}
              name="assignedUserIds"
              render={({ field }) => (
                <OrganizationUsersComponent
                  values={field.value}
                  organizationId={organizationId}
                  onChange={field.onChange}
                  disabled={disabled}
                  emptyMessageAddButton={t("no_more_users_to_assign")}
                  emptyMessage={t("no_assigned_users")}
                  tooltipAddButton={t("add_assigned_user_tooltip")}
                />
              )}
            />
          </Box>
        </Box>
      </Box>

      {/* Linked Sources */}
      <Box>
        <Typography variant="subtitle1" mb={0.75}>
          {t("labels.linked_sources", { ns: "linked_sources" })}
        </Typography>
        <Controller
          control={control}
          name="sourceIds"
          render={({ field }) => (
            <LinkedSourcesChipsListEditor
              organizationId={organizationId}
              sourceIds={field.value}
              onChange={field.onChange}
              noLinkedSourcesText={t("no_linked_sources", { ns: "linked_sources" })}
              addButtonTooltip={t("add_linked_source_tooltip", { ns: "linked_sources" })}
              disabled={disabled}
            />
          )}
        />
      </Box>
    </Box>
  );
};
