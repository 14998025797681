import {
  IDataEntryObjectInputParameterValueValueForKey,
  IInputParameterValueMetaData,
} from "@netcero/netcero-core-api-client";

export type DataEntryObjectInputParameterValueType = IInputParameterValueMetaData["type"];

export type DataEntryObjectInputParameterValueValuesPerKey = Record<
  string,
  IDataEntryObjectInputParameterValueValueForKey
>;

export type OptionalDefinition<R> = R | undefined;

export type OptionalDataEntryObjectInputParameterValueDefinition =
  OptionalDefinition<DataEntryObjectInputParameterValueDefinition>;

export type DataEntryObjectInputParameterValueDefinition =
  | DataEntryObjectInputParameterValueDefinitionForNumber
  | DataEntryObjectInputParameterValueDefinitionForBoolean
  | DataEntryObjectInputParameterValueDefinitionForOptions
  | DataEntryObjectInputParameterValueDefinitionForNestedOptions
  | DataEntryObjectInputParameterValueDefinitionForText
  | DataEntryObjectInputParameterValueDefinitionForDate
  | DataEntryObjectInputParameterValueDefinitionForDateRange
  | DataEntryObjectInputParameterValueDefinitionForCurrency
  | DataEntryObjectInputParameterValueDefinitionForPolicy
  | DataEntryObjectInputParameterValueDefinitionForAction
  | DataEntryObjectInputParameterValueDefinitionForYear
  | DataEntryObjectInputParameterValueDefinitionForMass;

// The expected values for the respective input types
export type DataEntryObjectInputParameterValueDefinitionForNumber = string;

export type DataEntryObjectInputParameterValueDefinitionForBoolean = boolean;

export type DataEntryObjectInputParameterValueDefinitionForOptions =
  | DataEntryObjectInputParameterValueDefinitionForOptionsSingle
  | DataEntryObjectInputParameterValueDefinitionForOptionsMultiple;
export type DataEntryObjectInputParameterValueDefinitionForOptionsSingle = string;
export type DataEntryObjectInputParameterValueDefinitionForOptionsMultiple = string[];

export type DataEntryObjectInputParameterValueDefinitionForNestedOptions =
  | DataEntryObjectInputParameterValueDefinitionForNestedOptionsSingle
  | DataEntryObjectInputParameterValueDefinitionForNestedOptionsMultiple;
export type DataEntryObjectInputParameterValueDefinitionForNestedOptionsSingle = string;
export type DataEntryObjectInputParameterValueDefinitionForNestedOptionsMultiple = string[];

export type DataEntryObjectInputParameterValueDefinitionForText =
  | DataEntryObjectInputParameterValueDefinitionForTextSingle
  | DataEntryObjectInputParameterValueDefinitionForTextMultiple;
export type DataEntryObjectInputParameterValueDefinitionForTextSingle = string;
export type DataEntryObjectInputParameterValueDefinitionForTextMultiple = string[];

export type DataEntryObjectInputParameterValueDefinitionForDate = string;

export type DataEntryObjectInputParameterValueDefinitionForDateRange = [string, string];

export type DataEntryObjectInputParameterValueDefinitionForCurrency = string;

export type DataEntryObjectInputParameterValueDefinitionForPolicy = boolean;
export type DataEntryObjectInputParameterValueDefinitionForAction = boolean;

export type DataEntryObjectInputParameterValueDefinitionForYear = number;

export enum DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit {
  g = "g",
  kg = "kg",
  t = "t",
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export interface DataEntryObjectInputParameterValueDefinitionForMass {
  value: number;
  unit: DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit;
}
