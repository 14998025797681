import { IDataEntryObject, IDMAEffectAreaType } from "@netcero/netcero-core-api-client";
import { Box, FormHelperText, Grid, Typography } from "@mui/material";
import { CheckboxWithArrayValueAndLabel } from "../../common/components/checkbox-with-array-value-and.label";
import { Controller, ControllerRenderProps, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FC, useEffect } from "react";
import { DataEntryObjectPickerDialog } from "../dialogs/data-entry-organisations/data-entry-object-picker.dialog";
import { useDialogStateWithoutData } from "../../common/dialogs/dialog-state.hook";
import MultipleSelectChip from "../../common/components/multiple-chip.component";
import { DataEntryObjectsAppUtilities } from "../../data-entry-objects/data-entry-objects-app.utilities";
import { IInternalFinancialEffectData } from "../financial-effects/internal-financial-effect-data.types";

type IIROAreasCheckboxesRelevantProps = Pick<
  IInternalFinancialEffectData,
  "areas" | "associatedDataEntryObjects"
>;

interface IIROAreasCheckboxesComponentProps {
  dataEntryObject: IDataEntryObject;
  disabled?: boolean;
}

interface ICheckboxProps {
  area: IDMAEffectAreaType;
  field: ControllerRenderProps<IIROAreasCheckboxesRelevantProps, "areas">;
  disabled: boolean | undefined;
}

const idToName = (fieldValue: string[], dataEntryObject: IDataEntryObject): string[] => {
  return fieldValue
    .map((id) => DataEntryObjectsAppUtilities.getDataEntryObjectNameBasedOnId(dataEntryObject, id))
    .filter((name): name is string => name !== undefined);
};

const CheckboxInput = ({ area, field, disabled }: ICheckboxProps) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");

  return (
    <Grid item xs={12} md={6} lg={4}>
      <CheckboxWithArrayValueAndLabel
        label={t(`${area}_name`)}
        value={field.value}
        checkboxValue={area}
        onChange={field.onChange}
        checkboxProps={{ size: "small" }}
        disabled={disabled}
      />
    </Grid>
  );
};

export const IROAreasCheckboxesComponent: FC<IIROAreasCheckboxesComponentProps> = ({
  dataEntryObject,
  disabled,
}) => {
  const { t } = useTranslation("dma_impact_or_effect_edit_dialog_common");

  const {
    isOpen: isDataEntryDialogOpen,
    openDialog: openDataEntryDialog,
    closeDialog: closeDataEntryDialog,
  } = useDialogStateWithoutData();

  const {
    control,
    trigger,
    watch,
    clearErrors,
    formState: { submitCount },
  } = useFormContext<IIROAreasCheckboxesRelevantProps>();

  useEffect(() => {
    // Skip if not submitted yet (no errors should be shown yet)
    if (submitCount < 1) {
      clearErrors(["areas", "associatedDataEntryObjects"]);
      return;
    }
    void trigger("areas");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch("associatedDataEntryObjects"), submitCount, trigger, clearErrors]);

  return (
    <Grid container alignItems="center" justifyContent="space-between" gap={2}>
      <Controller
        control={control}
        name="areas"
        rules={{
          validate: (value, formValues) => {
            if (value.length === 0 && formValues.associatedDataEntryObjects.length === 0) {
              return t("error_effect_area_required");
            }

            return true;
          },
        }}
        render={({ field, fieldState: { error } }) => {
          return (
            <Box px={1}>
              <Typography variant="subtitle1">
                {t("areas_select_label", { ns: "dma_impact_or_effect_edit_dialog_common" })}
              </Typography>
              {error && <FormHelperText error>{error.message}</FormHelperText>}
              <Grid container alignItems="center" justifyContent="space-between" gap={2}>
                <Grid item xs>
                  <CheckboxInput
                    area={IDMAEffectAreaType.UpstreamValueChain}
                    field={field}
                    disabled={disabled}
                  />
                </Grid>
                <Grid item xs>
                  <Controller
                    control={control}
                    name="associatedDataEntryObjects"
                    render={({ field, fieldState: { isDirty } }) => {
                      return (
                        <>
                          <MultipleSelectChip
                            label={t(`own-organization_name`)}
                            onSelectClick={openDataEntryDialog}
                            chipNames={idToName(field.value, dataEntryObject)}
                          />
                          <DataEntryObjectPickerDialog
                            open={isDataEntryDialogOpen}
                            onClose={closeDataEntryDialog}
                            title={t("own-organization_name")}
                            dataEntryObject={dataEntryObject}
                            checkedIds={field.value}
                            onChangeCheckedIds={field.onChange}
                            disabled={disabled}
                          />
                        </>
                      );
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <CheckboxInput
                    area={IDMAEffectAreaType.DownstreamValueChain}
                    field={field}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            </Box>
          );
        }}
      />
    </Grid>
  );
};
