import {
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IPolicyData,
  IPolicyDataS,
} from "@netcero/netcero-core-api-client";

export type PolicySDataOnly = Omit<IPolicyDataS, "s1" | "s2" | "s3" | "s4">;

export type IPolicyEditDialogData = IPolicyData;

export type IPolicyEditDialogSectionKey =
  | "e1"
  | "e2"
  | "e3"
  | "e4"
  | "e5"
  | "s1"
  | "s2"
  | "s3"
  | "s4";

export class PoliciesAppUtilities {
  public static getIdentifierKeyForTopic(
    topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  ): IPolicyEditDialogSectionKey {
    switch (topicIdentifier) {
      case "esrs_e1":
        return "e1";
      case "esrs_e2":
        return "e2";
      case "esrs_e3":
        return "e3";
      case "esrs_e4":
        return "e4";
      case "esrs_e5":
        return "e5";
      case "esrs_s1":
        return "s1";
      case "esrs_s2":
        return "s2";
      case "esrs_s3":
        return "s3";
      case "esrs_s4":
        return "s4";
    }
  }

  public static isSTopic(
    identifier: IPolicyEditDialogSectionKey,
  ): identifier is "s1" | "s2" | "s3" | "s4" {
    return identifier.startsWith("s");
  }

  public static isETopic(
    identifier: IPolicyEditDialogSectionKey,
  ): identifier is "e1" | "e2" | "e3" | "e4" | "e5" {
    return identifier.startsWith("e");
  }
}
