import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
} from "@mui/material";
import { IUserRepresentation } from "@netcero/phase-two-api-client";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ErrorTextComponent } from "../../common/components/error-text.component";
import { useSortedOrganizationUsersHook } from "../hooks/use-sorted-organization-users.hook";
import { UserAvatar } from "./user-avatar.component";
import { UserItem } from "./user-item.component";
import { NoUserAvatar } from "./no-user-selected.avatar.component";
import { HOVER_BACKGROUND_COLOR } from "../../../theme/theme";

const ValueBoxSx = {
  p: 1,
  borderRadius: 2,
  ":hover": { backgroundColor: HOVER_BACKGROUND_COLOR },
};

interface IOrganizationUserPickerProps {
  organizationId: string;
  value: string | null;
  includeUserIds?: string[];
  excludeUserIds?: string[];
  label?: string;
  placeholder?: string;
  helperText?: string;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onChange: (userData: IUserRepresentation | null) => void;
}

export const OrganizationUserPicker: FC<IOrganizationUserPickerProps> = ({
  organizationId,
  value,
  label,
  placeholder,
  includeUserIds,
  excludeUserIds,
  helperText,
  error,
  disabled,
  readOnly,
  onChange,
}) => {
  const { t } = useTranslation("user_picker_components");

  const [sortedUsers, users] = useSortedOrganizationUsersHook(organizationId);

  const shownUsers = useMemo(() => {
    if (excludeUserIds) {
      return sortedUsers?.filter((user) => !excludeUserIds?.includes(user.id!));
    }
    if (includeUserIds) {
      return sortedUsers?.filter((user) => includeUserIds?.includes(user.id!));
    }

    return sortedUsers;
  }, [sortedUsers, excludeUserIds, includeUserIds]);

  const handleOnChange = (userId: string | null) => {
    const selectedUser = users.data?.find((user) => user.id === userId);
    onChange(selectedUser ?? null);
  };

  if (users.isLoading) {
    return <Skeleton variant="rounded" width={240} height={56} />;
  }

  if (users.isError) {
    return <ErrorTextComponent error={users.error} />;
  }

  return (
    <FormControl error={error} sx={{ minWidth: 240 }}>
      {label && <InputLabel id="user-picker-select-label">{label}</InputLabel>}
      <Select
        variant="standard"
        disableUnderline
        value={value ?? ""}
        onChange={(evt) => handleOnChange(evt.target.value)}
        label={label}
        placeholder={placeholder}
        readOnly={readOnly}
        displayEmpty
        IconComponent={() => null}
        sx={{ "> .MuiSelect-select": ValueBoxSx }}
        renderValue={(userId) => {
          if (!userId) {
            return (
              <Box py={0.5}>
                <NoUserSelectedContent diameter={20} />
              </Box>
            );
          }

          const selectedUser = users.data?.find((user) => user.id === userId);

          if (!selectedUser) {
            return <Box>{t("selected_user_not_found")}</Box>;
          }

          return (
            <Box display="flex" alignItems="center" gap={1}>
              <UserAvatar user={selectedUser} />
              {selectedUser.firstName} {selectedUser.lastName}
            </Box>
          );
        }}
        error={error}
        disabled={disabled}
      >
        <MenuItem value="" sx={{ textAlign: "center" }}>
          <NoUserSelectedContent diameter={28} />
        </MenuItem>
        {shownUsers?.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            <UserItem user={user} />
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  );
};

interface INoUserSelectedContentProps {
  diameter: number;
}

const NoUserSelectedContent: FC<INoUserSelectedContentProps> = ({ diameter }) => {
  const { t } = useTranslation("user_picker_components");
  return (
    <Box display="flex" alignItems="center" gap={1} height={diameter}>
      <NoUserAvatar avatarDiameter={diameter} />
      {t("no_user_selected")}
    </Box>
  );
};
