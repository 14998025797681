import {
  IDataEntryObjectInputParameterValueSimpleValue,
  IInputParameterValue,
  IInputParameterValueMetaData,
  IInputParameterValueMetaDataCurrency,
  IInputParameterValueMetaDataDate,
  IInputParameterValueMetaDataDateRange,
  IInputParameterValueMetaDataMass,
  IInputParameterValueMetaDataNestedOptions,
  IInputParameterValueMetaDataNumber,
  IInputParameterValueMetaDataOptions,
  IInputParameterValueMetaDataText,
  IInputParameterValueMetaDataYear,
} from "@netcero/netcero-core-api-client";
import * as Joi from "joi";
import { EnumUtilities, RecursiveUtilities } from "../common";
import {
  DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit,
  OptionalDataEntryObjectInputParameterValueDefinition,
} from "./data-entry-object-input-parameter-values.public-types";

// disable strict only for numbers
const JoiDefaults = Joi.defaults((schema) => schema.required().strict(schema.type !== "number"));

export class DataEntryObjectInputParameterValuesVerification {
  public static verifyValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
    metaData: IInputParameterValueMetaData,
  ): Joi.ValidationError | undefined {
    switch (metaData.type) {
      case "number":
        return this.verifyNumberValue(value, metaData);
      case "boolean":
      case "action": // since only the value for the mode is stored, treat it just like a boolean
      case "policy": // since only the value for the mode is stored, treat it just like a boolean
        return this.verifyBooleanValue(value);
      case "options":
        return this.verifyOptionsValue(value, metaData);
      case "nested-options":
        return this.verifyNestedOptionsValue(value, metaData);
      case "text":
        return this.verifyTextValue(value, metaData);
      case "currency":
        return this.verifyCurrencyValue(value, metaData);
      case "date":
        return this.verifyDateValue(value, metaData);
      case "date-range":
        return this.verifyDateRangeValue(value, metaData);
      case "year":
        return this.verifyYearValue(value, metaData);
      case "mass":
        return this.verifyMassValue(value, metaData);
      // Fallback for API updates
      default:
        throw new Error(
          `Unknown input parameter value type: ${(metaData as IInputParameterValueMetaData).type}`,
        );
    }
  }

  private static verifyMassValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    _metaData: IInputParameterValueMetaDataMass,
  ): Joi.ValidationError | undefined {
    const schema = JoiDefaults.object({
      value: JoiDefaults.number().min(0),
      unit: JoiDefaults.string().valid(
        ...EnumUtilities.getValuesOfEnum(
          DataEntryObjectInputParameterValueDefinitionForMassAvailableUnit,
        ),
      ),
    });

    return schema.validate(value).error;
  }

  private static verifyYearValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
    { valueLimits }: IInputParameterValueMetaDataYear,
  ): Joi.ValidationError | undefined {
    // Years always have to be integers within the range
    let yearSchema = JoiDefaults.number().integer();

    if (valueLimits.min !== undefined) {
      yearSchema = yearSchema.min(valueLimits.min);
    }

    if (valueLimits.max !== undefined) {
      yearSchema = yearSchema.max(valueLimits.max);
    }

    return yearSchema.validate(value).error;
  }

  private static verifyCurrencyValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
    { valueLimits }: IInputParameterValueMetaDataCurrency,
  ): Joi.ValidationError | undefined {
    // integer as value is stored as eurocent
    let currencySchema = JoiDefaults.number().integer();

    if (valueLimits.min !== undefined) {
      currencySchema = currencySchema.min(valueLimits.min);
    }
    if (valueLimits.max !== undefined) {
      currencySchema = currencySchema.max(valueLimits.max);
    }

    return currencySchema.validate(value).error;
  }

  private static verifyDateValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
    { valueLimits }: IInputParameterValueMetaDataDate,
  ): Joi.ValidationError | undefined {
    let dateSchema = JoiDefaults.date().strict(false).iso();

    if (valueLimits.min !== undefined) {
      dateSchema = dateSchema.min(valueLimits.min);
    }
    if (valueLimits.max !== undefined) {
      dateSchema = dateSchema.max(valueLimits.max);
    }

    return dateSchema.validate(value).error;
  }

  private static verifyDateRangeValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
    { valueLimits }: IInputParameterValueMetaDataDateRange,
  ): Joi.ValidationError | undefined {
    // schema for the individual values
    let dateRangeSchema = JoiDefaults.date().strict(false).iso();

    if (valueLimits.min !== undefined) {
      dateRangeSchema = dateRangeSchema.min(valueLimits.min);
    }
    if (valueLimits.max !== undefined) {
      dateRangeSchema = dateRangeSchema.max(valueLimits.max);
    }

    // first, validate that the value is indeed an array with [start, end] as strings
    const arraySchema = JoiDefaults.array().items(dateRangeSchema).length(2);
    const error = arraySchema.validate(value).error;

    if (error) {
      return error;
    }

    // both passed validation --> safe to assume it is the correct value already
    const validatedValue = value as [string, string];

    // check that the start is before the end
    return JoiDefaults.date()
      .strict(false)
      .iso()
      .greater(validatedValue[0])
      .validate(validatedValue[1]).error;
  }

  private static verifyNumberValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
    { valueLimits }: IInputParameterValueMetaDataNumber,
  ): Joi.ValidationError | undefined {
    // TODO: maybe prevent conversion at some point
    let numberSchema = JoiDefaults.number().precision(valueLimits.precision);

    if (valueLimits.min !== undefined) {
      numberSchema = numberSchema.min(valueLimits.min);
    }
    if (valueLimits.max !== undefined) {
      numberSchema = numberSchema.max(valueLimits.max);
    }

    return (
      numberSchema.validate(value).error ??
      JoiDefaults.number()
        .strict()
        .precision(valueLimits.precision)
        .validate(+(value as string)).error
    );
  }

  private static verifyBooleanValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
  ): Joi.ValidationError | undefined {
    return JoiDefaults.boolean().validate(value).error;
  }

  private static verifyOptionsValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
    { options, multiple }: IInputParameterValueMetaDataOptions,
  ): Joi.ValidationError | undefined {
    const schemaForSingleValue = JoiDefaults.string().valid(
      ...options.map((option) => option.value),
    );

    // multiple values should be stored --> verify that each of them is valid and
    // that there's at least one
    if (multiple) {
      const schema = JoiDefaults.array().items(schemaForSingleValue).min(1).unique();
      return schema.validate(value).error;
    }

    // single --> verify that value is one of the options
    return schemaForSingleValue.validate(value).error;
  }

  private static verifyNestedOptionsValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
    { options, multiple }: IInputParameterValueMetaDataNestedOptions,
  ): Joi.ValidationError | undefined {
    const schemaForSingleValue = JoiDefaults.string().valid(
      ...options
        .flatMap((o) => RecursiveUtilities.flattenRecursiveStructureDown(o))
        // be sure to only allow saving leaves
        .filter((o) => o.children.length === 0)
        .map((option) => option.value),
    );

    // multiple values should be stored --> verify that each of them is valid and
    // that there's at least one
    if (multiple) {
      const schema = JoiDefaults.array().items(schemaForSingleValue).min(1).unique();
      return schema.validate(value).error;
    }

    // single --> verify that value is one of the options
    return schemaForSingleValue.validate(value).error;
  }

  private static verifyTextValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
    metaData: IInputParameterValueMetaDataText,
  ): Joi.ValidationError | undefined {
    const schemaForSingleValue = JoiDefaults.string().trim().min(1);

    // multiple --> make sure that each item meets the criteria
    if (metaData.multipart) {
      const schema = JoiDefaults.array().items(schemaForSingleValue);
      return schema.validate(value).error;
    }

    // single --> just ensure that the single item is valid
    return schemaForSingleValue.validate(value).error;
  }

  /**
   * CAREFUL: only call this after you've ensured that the value matches the metadata!
   */
  public static sanitizeValue(
    value: OptionalDataEntryObjectInputParameterValueDefinition,
    metaData: IInputParameterValueMetaData,
    preventTrim: boolean = false,
  ): OptionalDataEntryObjectInputParameterValueDefinition {
    if (value === undefined) {
      return value;
    }

    switch (metaData.type) {
      case "number": {
        const typedValue = value as string;
        return (+typedValue).toString();
      }
      case "text": {
        if (metaData.multipart) {
          const typedValue = value as string[];
          return typedValue.map((part) => part.trim()).filter((part) => part.length > 0);
        } else {
          const typedValue = value as string;
          return preventTrim ? typedValue || undefined : typedValue.trim() || undefined;
        }
      }
      default:
        return value;
    }
  }

  public static sanitizeValues(
    values: Record<string, IDataEntryObjectInputParameterValueSimpleValue | undefined>,
    inputParameterValues: IInputParameterValue[],
    preventTrim: boolean = false,
  ) {
    const result: Record<string, IDataEntryObjectInputParameterValueSimpleValue | undefined> = {
      ...values,
    };

    inputParameterValues.forEach((inputParameterValue) => {
      const currentValue = values[inputParameterValue.key];
      if (!currentValue) {
        return;
      }

      if (inputParameterValue.valueConfiguration.type === "simple") {
        const sanitizedValue = DataEntryObjectInputParameterValuesVerification.sanitizeValue(
          currentValue.value,
          inputParameterValue.valueConfiguration.configuration,
          preventTrim,
        );
        result[inputParameterValue.key] = {
          ...currentValue,
          value: sanitizedValue,
        };
      }
    });

    return result;
  }
}
