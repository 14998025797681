import { RecursiveKeyOf } from "../common";
import { IActionData } from "@netcero/netcero-core-api-client";
import { MDRDependencies } from "../mdrs/mdrs.public-types";
import { MDRUtilities } from "../mdrs/mdrs.utilities";

export type KeysForAction = RecursiveKeyOf<
  Omit<IActionData, "materialImpactIds" | "financialEffectIds">
>;
type IDependenciesForActions = MDRDependencies<KeysForAction>;

const createBooleanEntry = MDRUtilities.createBooleanMDRDependencyFactory<KeysForAction>();

export const DEPENDENCIES_FOR_ACTION: IDependenciesForActions = {
  "e4.disclosureOfAimOfBiodiversityOffsetAndKeyPerformanceIndicatorsUsedExplanatory":
    createBooleanEntry("e4.biodiversityOffsetsWereUsedInActionPlan"),
  "e4.financingEffectsDirectAndIndirectCostsOfBiodiversityOffsets": createBooleanEntry(
    "e4.biodiversityOffsetsWereUsedInActionPlan",
  ),
  "e4.descriptionOfBiodiversityOffsetsExplanatory": createBooleanEntry(
    "e4.biodiversityOffsetsWereUsedInActionPlan",
  ),
  "e4.descriptionOfHowLocalAndIndigenousKnowledgeAndNatureBasedSolutionsHaveBeenIncorporatedIntoBiodiversityAndEcosystemsrelatedActionExplanatory":
    createBooleanEntry(
      "e4.localAndIndigenousKnowledgeAndNaturebasedSolutionsHaveBeenIncorporatedIntoBiodiversityAndEcosystemsrelatedAction",
    ),
  "e4.disclosureOfAdditionalInformationAboutProjectItsSponsorsAndOtherParticipantsBiodiversityAndEcosystemsExplanatory":
    createBooleanEntry(
      "e4.keyActionPlanIsPartOfWiderInitiativeToWhichUndertakingSignificantlyContributesBiodiversityAndEcosystems",
    ),
  "e5.disclosureOfHowActionCoversHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWaterExplanatory":
    createBooleanEntry(
      "e5.actionCoversHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWater",
    ),
  "e5.disclosureOfHowActionCoversHigherRatesOfUseOfSecondaryRawMaterialsRecyclatesExplanatory":
    createBooleanEntry("e5.actionCoversHigherRatesOfUseOfSecondaryRawMaterialsRecyclates"),
  "e5.disclosureOfHowActionCoversApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecyclingExplanatory":
    createBooleanEntry(
      "e5.actionCoversApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecycling",
    ),
  "e5.disclosureOfHowActionCoversApplicationOfCircularBusinessPracticesExplanatory":
    createBooleanEntry("e5.actionCoversApplicationOfCircularBusinessPractices"),
  "e5.disclosureOfHowActionCoversActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChainExplanatory":
    createBooleanEntry(
      "e5.actionCoversActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChain",
    ),
  "e5.disclosureOfHowActionCoversOptimisationOfWasteManagementInLineWithWasteHierarchyExplanatory":
    createBooleanEntry(
      "e5.disclosureOfHowActionCoversOptimisationOfWasteManagementInLineWithWasteHierarchyExplanatory",
    ),
  "e5.disclosureOfHowResourcesCoverHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWaterExplanatory":
    createBooleanEntry(
      "e5.resourcesCoverHigherLevelsOfResourceEfficiencyInUseOfTechnicalAndBiologicalMaterialsAndWater",
    ),
  "e5.disclosureOfHowResourcesCoverHigherRatesOfUseOfSecondaryRawMaterialsRecyclatesExplanatory":
    createBooleanEntry("e5.resourcesCoverHigherRatesOfUseOfSecondaryRawMaterialsRecyclates"),
  "e5.disclosureOfHowResourcesCoverApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecyclingExplanatory":
    createBooleanEntry(
      "e5.resourcesCoverApplicationOfCircularDesignAndHigherRatesOfReuseRepairRefurbishingRemanufactureRepurposingAndRecycling",
    ),
  "e5.disclosureOfHowResourcesCoverApplicationOfCircularBusinessPracticesExplanatory":
    createBooleanEntry("e5.resourcesCoverApplicationOfCircularBusinessPractices"),
  "e5.disclosureOfHowResourcesCoverActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChainExplanatory":
    createBooleanEntry(
      "e5.resourcesCoverActionsTakenToPreventWasteGenerationInUpstreamAndDownstreamValueChain",
    ),
  "e5.disclosureOfHowResourcesCoverOptimisationOfWasteManagementInLineWithWasteHierarchyExplanatory":
    createBooleanEntry("e5.resourcesCoverOptimisationOfWasteManagementInLineWithWasteHierarchy"),
};

export const ACTION_INPUTS_DEPENDED_UPON: KeysForAction[] = Array.from(
  new Set(Object.values(DEPENDENCIES_FOR_ACTION).map((d) => d.dependsUpon)),
);
