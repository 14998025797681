import {
  DEPENDENCIES_FOR_POLICY,
  KeysForPolicy,
  POLICY_INPUTS_DEPENDED_UPON,
} from "./dependencies";
import { IPolicy, IPolicyData } from "@netcero/netcero-core-api-client";
import { MDRUtilities } from "../mdrs/mdrs.utilities";
import { pick } from "lodash";

export class PoliciesUtilities {
  /**
   * This method determines whether the input in question is available, based on the current state of the policy.
   * @param inputInQuestion The input for which the state should be determined
   * @param value The current state of the policy
   */
  public static isInputAvailable(inputInQuestion: KeysForPolicy, value: IPolicyData) {
    return MDRUtilities.shouldInputBeShown<KeysForPolicy>(
      inputInQuestion,
      value,
      DEPENDENCIES_FOR_POLICY,
    );
  }

  /**
   * This method determines whether the input in question is depended upon by another input.
   * @param inputInQuestion The input for which to check whether other inputs depend on it
   */
  public static isInputDependedUponByAnother(inputInQuestion: KeysForPolicy) {
    return POLICY_INPUTS_DEPENDED_UPON.includes(inputInQuestion);
  }

  /**
   * This method determines whether the input in question is conditional.
   * @param inputInQuestion The input for which to check whether it is conditional
   */
  public static isInputConditional(inputInQuestion: KeysForPolicy) {
    return DEPENDENCIES_FOR_POLICY[inputInQuestion] !== undefined;
  }

  /**
   * This method converts a policy to policy data.
   * @param policy The policy to convert
   */
  public static convertPolicyToPolicyData(policy: IPolicy): IPolicyData {
    return pick(policy, [
      "associatedTopics",
      "general",
      "e1",
      "e2",
      "e3",
      "e4",
      "e5",
      "s",
      "materialImpactIds",
      "financialEffectIds",
    ]);
  }
}
