import React from "react";
import { ActionsUtilities } from "@netcero/netcero-common";
import { IActionData } from "@netcero/netcero-core-api-client";
import { useTranslation } from "react-i18next";
import {
  IMdrTopicSectionCommonProps,
  MdrTopicSection,
} from "../../minimum-disclosure-requirements-common/components/mdr-topic-section.component";

type IActionTopicComponentProps = IMdrTopicSectionCommonProps<IActionData>;

export function ActionTopicComponent(props: IActionTopicComponentProps) {
  const { t } = useTranslation("data_entry_object_action_component");

  return (
    <MdrTopicSection
      {...props}
      t={t}
      isInputConditional={ActionsUtilities.isInputConditional}
      isInputDependedUponByAnother={ActionsUtilities.isInputDependedUponByAnother}
      isInputAvailable={ActionsUtilities.shouldInputBeShown}
    />
  );
}
