import { Box, Button, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  DataEntryObjectInputParameterValueDefinitionForAction,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IAction,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
} from "@netcero/netcero-core-api-client";
import { useActionsQuery } from "../actions.queries";
import { ActionsTable } from "./actions-table.component";
import { QueryWrapper } from "../../common/components/query-wrapper.component";

interface IActionsListComponentProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  rootDataEntryObjectId: string;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForAction>;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForAction) => void;
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  disabled?: boolean;
}

export const ActionsListComponent: FC<IActionsListComponentProps> = (props) => {
  const actionsQuery = useActionsQuery(
    props.organizationId,
    props.recordingPeriodId,
    props.dataEntryObjectId,
  );

  return (
    <QueryWrapper
      query={actionsQuery}
      build={(actions) => <ActionsListComponentInternal actions={actions} {...props} />}
    />
  );
};

interface IActionsListInternalProps extends IActionsListComponentProps {
  actions: IAction[];
}

const ActionsListComponentInternal: FC<IActionsListInternalProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  rootDataEntryObjectId,
  actions,
  value,
  onChange,
  topicIdentifier,
  disabled,
}) => {
  const filteredActions = useMemo(
    () => actions.filter((action) => action.associatedTopics.includes(topicIdentifier)),
    [actions, topicIdentifier],
  );

  const { t } = useTranslation("data_entry_object_action_component");

  if (value === undefined) {
    return (
      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          size="small"
          color="success"
          onClick={() => onChange(true)}
          disabled={disabled}
          sx={{ minWidth: 124 }}
        >
          {t("present_actions")}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={() => onChange(false)}
          disabled={disabled}
          sx={{ minWidth: 124 }}
        >
          {t("no_present_actions")}
        </Button>
      </Box>
    );
  }

  return value ? (
    <ActionsTable
      actions={filteredActions}
      topicIdentifier={topicIdentifier}
      organizationId={organizationId}
      recordingPeriodId={recordingPeriodId}
      dataEntryObjectId={dataEntryObjectId}
      rootDataEntryObjectId={rootDataEntryObjectId}
      onChange={onChange}
      disabled={disabled}
    />
  ) : (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography>{t("notice_chosen_to_not_define_actions")}</Typography>
      <Button
        variant="outlined"
        size="small"
        color="success"
        onClick={() => onChange(true)}
        disabled={disabled}
        sx={{ minWidth: 124 }}
      >
        {t("actions_adopted")}
      </Button>
    </Box>
  );
};
