import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { FC, useCallback, useMemo } from "react";
import { TreeUtilities } from "../../common/utilities/tree.utilities";
import { useTranslation } from "react-i18next";

export type IHierarchyDropdownType = "single" | "multiple";

interface IHierarchyDropdownPropsCommon {
  type: IHierarchyDropdownType;
  label: string;
  translateValueLabel: (value: string) => string;
  values: string[];
  disabled: boolean;
}

interface IHierarchyDropdownPropsSingle extends IHierarchyDropdownPropsCommon {
  type: "single";
  value: string;
  onChange: (newValue: string | null) => void;
  emptyOption?: boolean;
}

interface IHierarchyDropdownPropsMultiple extends IHierarchyDropdownPropsCommon {
  type: "multiple";
  value: string[];
  onChange: (newValue: string[]) => void;
  emptyOption?: undefined;
}

type IHierarchyDropdownProps = IHierarchyDropdownPropsSingle | IHierarchyDropdownPropsMultiple;

interface IHierarchyEntry {
  value: string;
  label: string;
  children: IHierarchyEntry[];
}

interface IFlattenedHierarchyEntry {
  depth: number;
  item: IHierarchyEntry;
}

const buildHierarchy = (
  flatData: string[],
  getLabel: (value: string) => string,
): IHierarchyEntry[] => {
  const roots: IHierarchyEntry[] = [];

  flatData.forEach((path) => {
    const parts = path.split(".");
    let currentLevel = roots;

    parts.forEach((part, index) => {
      const value = parts.slice(0, index + 1).join(".");
      let existingPath = currentLevel.find((p) => p.value === value);

      if (!existingPath) {
        const newPart: IHierarchyEntry = {
          value: value,
          label: getLabel(value),
          children: [],
        };

        currentLevel.push(newPart);
        existingPath = newPart;
      }

      currentLevel = existingPath.children;
    });
  });

  return roots;
};

export const HierarchicalDropdownEnum: FC<IHierarchyDropdownProps> = ({
  type,
  label,
  translateValueLabel,
  values,
  value,
  onChange,
  disabled,
  emptyOption,
}) => {
  const itemsHierarchy = useMemo(
    () => buildHierarchy(values, translateValueLabel),
    [values, translateValueLabel],
  );

  const flattenedHierarchy: IFlattenedHierarchyEntry[] = useMemo(
    () =>
      itemsHierarchy.flatMap((rootNode) =>
        TreeUtilities.flatMap(
          rootNode,
          (entry) => entry.children,
          (entry, depth) => ({
            item: entry,
            depth,
          }),
        ),
      ),
    [itemsHierarchy],
  );

  return type === "single" ? (
    <HierarchicalDropdownSingle
      label={label}
      flattenedOptions={flattenedHierarchy}
      value={value}
      emptyOption={emptyOption}
      onChange={onChange}
      disabled={disabled}
    />
  ) : (
    <HierarchicalDropdownMultiple
      label={label}
      flattenedOptions={flattenedHierarchy}
      value={value}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

interface IGroupedMultipleDropdownProps {
  label: string;
  flattenedOptions: IFlattenedHierarchyEntry[];
  value: string[];
  onChange: (newValue: string[]) => void;
  disabled: boolean;
}

interface IGroupedSingleDropdownProps {
  label: string;
  flattenedOptions: IFlattenedHierarchyEntry[];
  value: string;
  onChange: (newValue: string | null) => void;
  disabled: boolean;
  emptyOption?: boolean;
}

type ICheckboxState = "checked" | "unchecked" | "indeterminate";

function isOptionParent(option: IFlattenedHierarchyEntry) {
  return option.item.children.length > 0;
}

function getAllLeafValues(option: IFlattenedHierarchyEntry): string[] {
  return option.item.children
    .map((child) =>
      TreeUtilities.flatMap(
        child,
        (o) => o.children,
        (o) => (o.children.length === 0 ? o.value : null),
      ),
    )
    .flat()
    .filter((v) => v !== null) as string[];
}

function getParentOptionState(option: IFlattenedHierarchyEntry, value: string[]): ICheckboxState {
  const allLeafValues = getAllLeafValues(option);
  const selectedChildValuesCount = allLeafValues.filter((v) => value.includes(v)).length;
  return selectedChildValuesCount === allLeafValues.length
    ? "checked"
    : selectedChildValuesCount > 0
    ? "indeterminate"
    : "unchecked";
}

function getLeafOptionState(option: IFlattenedHierarchyEntry, value: string[]): ICheckboxState {
  return value.includes(option.item.value) ? "checked" : "unchecked";
}

export const HierarchicalDropdownMultiple: FC<IGroupedMultipleDropdownProps> = ({
  label,
  value,
  onChange,
  flattenedOptions,
  disabled,
}) => {
  const checkboxStateLookup: Record<string, ICheckboxState> = useMemo(() => {
    const lookup: Record<string, ICheckboxState> = {};

    flattenedOptions.forEach((flattenedOption) => {
      lookup[flattenedOption.item.value] = isOptionParent(flattenedOption)
        ? getParentOptionState(flattenedOption, value)
        : getLeafOptionState(flattenedOption, value);
    });

    return lookup;
  }, [flattenedOptions, value]);

  const valueLabelLookup: Record<string, string> = useMemo(() => {
    const lookup: Record<string, string> = {};

    flattenedOptions.forEach((flattenedOption) => {
      lookup[flattenedOption.item.value] = flattenedOption.item.label;
    });

    return lookup;
  }, [flattenedOptions]);

  const handleClickValue = useCallback(
    (option: IFlattenedHierarchyEntry): (() => void) => {
      if (isOptionParent(option)) {
        // Handle Parent Value
        const allLeafValues = getAllLeafValues(option);
        const state = checkboxStateLookup[option.item.value];
        if (state === "checked") {
          return () => onChange(value.filter((v) => !allLeafValues.includes(v)));
        } else {
          return () => onChange(Array.from(new Set([...value, ...allLeafValues])));
        }
      } else {
        // Handle Leaf Value
        const state = checkboxStateLookup[option.item.value];
        if (state === "checked") {
          return () => onChange(value.filter((v) => v !== option.item.value));
        } else {
          return () => onChange(Array.from(new Set([...value, option.item.value])));
        }
      }
    },
    [checkboxStateLookup, onChange, value],
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="multiple-checkbox-label">{label}</InputLabel>
      <Select
        variant="outlined"
        labelId="multiple-checkbox-label"
        id="multiple-checkbox-label"
        multiple
        value={value}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={valueLabelLookup[value]} />
            ))}
          </Box>
        )}
        disabled={disabled}
      >
        {flattenedOptions.map((option) => (
          <MenuItem
            key={option.item.value}
            value={option.item.value}
            onClick={handleClickValue(option)}
          >
            <Checkbox
              checked={checkboxStateLookup[option.item.value] === "checked"}
              indeterminate={checkboxStateLookup[option.item.value] === "indeterminate"}
            />
            <ListItemText
              primary={option.item.label}
              primaryTypographyProps={{
                fontWeight:
                  option.depth === 0 && option.item.children.length > 0 ? "bold" : "normal",
              }}
              sx={{ pl: option.depth * 4 }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const HierarchicalDropdownSingle: FC<IGroupedSingleDropdownProps> = ({
  label,
  emptyOption,
  value,
  onChange,
  flattenedOptions,
  disabled,
}) => {
  const { t } = useTranslation("options_input_component");

  const valueLabelLookup: Record<string, string> = useMemo(() => {
    const lookup: Record<string, string> = {};
    flattenedOptions.forEach((flattenedOption) => {
      lookup[flattenedOption.item.value] = flattenedOption.item.label;
    });
    return lookup;
  }, [flattenedOptions]);

  const handleClickValue = useCallback(
    (optionValue: string | null) => {
      return () => onChange(optionValue);
    },
    [onChange],
  );

  return (
    <FormControl fullWidth>
      <InputLabel id="single-dropdown-label">{label}</InputLabel>
      <Select
        variant="outlined"
        labelId="single-dropdown-label"
        id="single-dropdown-select"
        value={value ?? ""}
        input={<OutlinedInput label={label} />}
        disabled={disabled}
        renderValue={(selected) => valueLabelLookup[selected]}
      >
        {emptyOption && (
          <MenuItem value="" onClick={handleClickValue(null)}>
            <em>{t("name_empty_option")}</em>
          </MenuItem>
        )}
        {flattenedOptions.map((option) => (
          <MenuItem
            key={option.item.value}
            value={option.item.value}
            sx={{ pl: 2 + option.depth * 4 }}
            onClick={handleClickValue(option.item.value)}
          >
            <ListItemText
              primary={option.item.label}
              primaryTypographyProps={{
                fontWeight:
                  option.depth === 0 && option.item.children.length > 0 ? "bold" : "normal",
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
