import {
  IHydratedCompleteInputParameterRecordingEsrsStructure,
  IHydratedCompleteInputParameterRecordingStructureGroupESRSDisclosureRequirementIP,
  IHydratedInputParameterRecordingEsrsStructure,
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement,
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP,
  IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
} from "./input-parameter-recording-esrs-structures.interfaces";
import { ILocalDataEntryObjectInputParameter } from "../../data-entry-object-values/interfaces/local-data-entry-object-values.interfaces";
import {
  IInputParameter,
  IInputParameterESRSMetaDataDRInformation,
  IInputParameterRecordingStructureGroupESRS,
  IInputParameterRecordingStructureGroupESRSDisclosureRequirement,
  IInputParameterRecordingStructureGroupESRSDisclosureRequirementIP,
  IInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
} from "@netcero/netcero-core-api-client";
import { InputParameterRecordingStructuresUtilities } from "../input-parameter-recording-structures.utilities";
import { isEnum } from "class-validator";

/** EFRAG IDs of DRs which are eligible for phase in */
export enum EligiblePhaseInDrsEfragIds {
  SBM_1 = "SBM-1",
  SBM_3 = "SBM-3",
  E1_6 = "E1-6",
  E1_9 = "E1-9",
  E2_6 = "E2-6",
  E3_5 = "E3-5",
  E4_6 = "E4-6",
  E5_6 = "E5-6",
  S1_7 = "S1-7",
  S1_8 = "S1-8",
  S1_11 = "S1-11",
  S1_12 = "S1-12",
  S1_13 = "S1-13",
  S1_14 = "S1-14",
  S1_15 = "S1-15",
}

export class InputParameterRecordingEsrsStructuresUtilities {
  /**
   * Hydrates the given ESRS structure with the given data entry object input parameters
   * @param esrsStructure The ESRS structure to hydrate
   * @param dataEntryObjectInputParameters The data entry object input parameters to hydrate the structure with
   */
  public static hydrateStructureStructureGroup(
    esrsStructure: IInputParameterRecordingStructureGroupESRS,
    dataEntryObjectInputParameters: ILocalDataEntryObjectInputParameter[],
  ): IHydratedInputParameterRecordingEsrsStructure {
    const inputParameterLookup =
      InputParameterRecordingStructuresUtilities.getInputParameterEntryLookupMap(
        dataEntryObjectInputParameters,
      );

    return {
      ...esrsStructure,
      sections: esrsStructure.sections.map((section) =>
        InputParameterRecordingEsrsStructuresUtilities.hydrateDisclosureRequirementSection(
          section,
          inputParameterLookup,
        ),
      ),
    };
  }

  private static hydrateDisclosureRequirementSection(
    section: IInputParameterRecordingStructureGroupESRSDisclosureRequirementSection,
    inputParametersLookup: Map<string, ILocalDataEntryObjectInputParameter>,
  ): IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementSection {
    return {
      ...section,
      disclosureRequirements: section.disclosureRequirements.map((disclosureRequirement) =>
        InputParameterRecordingEsrsStructuresUtilities.hydrateDisclosureRequirement(
          disclosureRequirement,
          inputParametersLookup,
        ),
      ),
    };
  }

  private static hydrateDisclosureRequirement(
    disclosureRequirement: IInputParameterRecordingStructureGroupESRSDisclosureRequirement,
    inputParametersLookup: Map<string, ILocalDataEntryObjectInputParameter>,
  ): IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement {
    const disclosureRequirementInputParameter = inputParametersLookup.get(
      disclosureRequirement.disclosureId,
    );

    if (!disclosureRequirementInputParameter) {
      console.warn(
        `Could not find disclosure requirement with id (input parameter that holds disclosure requirement data): ${disclosureRequirement.disclosureId}`,
      );
    }

    return {
      ...disclosureRequirement,
      disclosureRequirementInputParameter: disclosureRequirementInputParameter ?? null,
      inputParameters: disclosureRequirement.inputParameters.map((inputParameter) =>
        InputParameterRecordingEsrsStructuresUtilities.hydrateDisclosureRequirementInputParameter(
          inputParameter,
          inputParametersLookup,
        ),
      ),
    };
  }

  private static hydrateDisclosureRequirementInputParameter(
    inputParameter: IInputParameterRecordingStructureGroupESRSDisclosureRequirementIP,
    inputParametersLookup: Map<string, ILocalDataEntryObjectInputParameter>,
  ): IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP {
    const deoInputParameter = inputParametersLookup.get(inputParameter.parameterId);

    if (!deoInputParameter) {
      console.warn(
        `Could not find input parameter inside disclosure requirement id: ${inputParameter.parameterId}`,
      );
    }

    return {
      ...inputParameter,
      inputParameter: deoInputParameter ?? null,
    };
  }

  // Clean Up Structure Group

  public static cleanUpStructureGroup(
    hydratedStructure: IHydratedInputParameterRecordingEsrsStructure,
  ): [
    IHydratedCompleteInputParameterRecordingEsrsStructure,
    IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement[],
    IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP[],
  ] {
    const incompleteDisclosureRequirements: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirement[] =
      [];
    const incompleteInputParameters: IHydratedInputParameterRecordingStructureGroupESRSDisclosureRequirementIP[] =
      [];

    const completeStructure = {
      ...hydratedStructure,
      sections: hydratedStructure.sections.map((section) => ({
        ...section,
        disclosureRequirements: section.disclosureRequirements
          // Remove all incomplete disclosure requirements and add to incomplete list
          .filter((disclosureRequirement) => {
            if (disclosureRequirement.disclosureRequirementInputParameter === null) {
              incompleteDisclosureRequirements.push(disclosureRequirement);
              return false;
            }
            return true;
          })
          .map((disclosureRequirement) => ({
            ...disclosureRequirement,
            // Now only the complete disclosure requirements are left
            disclosureRequirementInputParameter:
              disclosureRequirement.disclosureRequirementInputParameter!,
            // Remove all incomplete disclosure requirements and add to incomplete list
            inputParameters: disclosureRequirement.inputParameters.filter((inputParameter) => {
              if (inputParameter.inputParameter === null) {
                incompleteInputParameters.push(inputParameter);
                return false;
              }
              // TODO: Remove once targets and actions are implemented
              // Remove currently unsupported IPs
              if (
                inputParameter.inputParameter.inputParameter.values.some(
                  (ipValue) =>
                    ipValue.valueConfiguration.type === "simple" &&
                    ipValue.valueConfiguration.configuration.type === "target",
                )
              ) {
                return false;
              }
              return true;
            }) as IHydratedCompleteInputParameterRecordingStructureGroupESRSDisclosureRequirementIP[], // This is fine since we filtered out the incomplete ones
          })),
      })),
    };

    return [completeStructure, incompleteDisclosureRequirements, incompleteInputParameters];
  }

  /**
   * Checks whether a given input parameter is eligible for phase in
   * @param inputParameter
   */
  public static isInputParameterEligibleForPhaseIn = (inputParameter: IInputParameter) => {
    return (
      inputParameter.metaData.esrs?.drInformation ===
      IInputParameterESRSMetaDataDRInformation.Eligible
    );
  };

  /**
   * Gets the translation key for the disclosure requirement of the given input parameter
   * @param inputParameter
   */
  public static getDisclosureRequirementTranslationKey(inputParameter: IInputParameter) {
    if (isEnum(inputParameter.metaData.esrs?.efragId, EligiblePhaseInDrsEfragIds)) {
      return inputParameter.metaData.esrs?.efragId as EligiblePhaseInDrsEfragIds;
    }

    console.warn(
      "Tried to read Disclosure Requirement translation key for non-eligible input parameter",
    );

    return null;
  }
}
