import { Box, Divider, IconButton, Skeleton, Tooltip } from "@mui/material";
import {
  IAction,
  IActionData,
  IActionDataGeneral,
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
} from "@netcero/netcero-core-api-client";
import { FC, useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ActionsUtilities } from "@netcero/netcero-common";
import { DeleteIcon } from "../../common/constants/tabler-icon.constants";
import { MdrUtilities } from "../../minimum-disclosure-requirements-common/mdr.utilities";
import {
  ALL_SECTIONS_CONFIG,
  ALL_SECTIONS_ORDER,
  E1And4,
  E1And4_ORDER,
  GENERAL,
} from "../action-inputs.constants";
import { ActionTopicComponent } from "./action-topic.component";
import { ActionsAppUtilities, IActionEditDialogData } from "../actions-app.utilities";
import { EditDialogWrapper } from "../../common/dialogs/variants/edit-dialog.wrapper";
import { MdrTopicBreadcrumb } from "../../minimum-disclosure-requirements-common/components/mdr-topic-breadcrumb.component";
import { FormatTranslation } from "../../common/components/format-translation.component";
import { useDMACategoriesQuery } from "../../double-materiality-assessment/dma.queries";
import { usePoliciesQuery } from "../../policies/policies.queries";
import { QueriesWrapper } from "../../common/components/queries-wrapper.component";

const getFormDefaultValues = (
  action: IAction | undefined | null,
  identifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
): IActionEditDialogData => {
  const identifierKey = ActionsAppUtilities.getIdentifierKeyForTopic(identifier);

  const result: IActionEditDialogData = {
    ...(action ? ActionsUtilities.convertActionToActionData(action) : {}),
    materialImpactIds: action?.materialImpactIds ?? [],
    financialEffectIds: action?.financialEffectIds ?? [],
    policyIds: action?.policyIds ?? [],
    general: MdrUtilities.getDefaultValuesForConfiguration<IActionDataGeneral>(
      GENERAL,
      action?.general,
    ),
    associatedTopics: action?.associatedTopics ?? [identifier],
  };

  result[identifierKey] = MdrUtilities.getDefaultValuesForConfiguration(
    ALL_SECTIONS_CONFIG[identifierKey],
    action?.[identifierKey],
  ) as never;

  if (identifierKey === "e1" || identifierKey === "e4") {
    result.e1And4 = MdrUtilities.getDefaultValuesForConfiguration(E1And4, action?.e1And4);
  }

  return result;
};

interface IActionEditDialogPropsBase {
  open: boolean;
  loading: boolean;
  error?: Error | null;
  disabled?: boolean;
  onClose: (data: IActionData | null) => void;
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  organizationId: string;
  recordingPeriodId: string;
  rootDataEntryObjectId: string;
}

interface IActionEditDialogPropsCreate extends IActionEditDialogPropsBase {
  action?: undefined;
  onDelete?: undefined;
}

interface IActionEditDialogPropsEdit extends IActionEditDialogPropsBase {
  action: IAction | undefined | null;
  onDelete: (actionId: string) => void;
}

type IActionEditDialogProps = IActionEditDialogPropsCreate | IActionEditDialogPropsEdit;

export const ActionEditDialog: FC<IActionEditDialogProps> = ({
  disabled,
  onClose,
  onDelete,
  error,
  open,
  loading,
  action,
  topicIdentifier,
  organizationId,
  recordingPeriodId,
  rootDataEntryObjectId,
}) => {
  const mode = action ? "edit" : "create";

  const { t } = useTranslation("data_entry_object_action_component");
  const identifierKey = useMemo(
    () => ActionsAppUtilities.getIdentifierKeyForTopic(topicIdentifier),
    [topicIdentifier],
  );

  const policiesQuery = usePoliciesQuery(organizationId, recordingPeriodId);
  const dmaCategoriesQuery = useDMACategoriesQuery(
    organizationId,
    recordingPeriodId,
    rootDataEntryObjectId,
  );

  const useFormResult = useForm<IActionData>({
    defaultValues: getFormDefaultValues(action, topicIdentifier),
  });
  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    watch,
  } = useFormResult;

  useEffect(() => {
    if (open) {
      reset(getFormDefaultValues(action, topicIdentifier));
    }
  }, [action, open, reset, topicIdentifier]);

  const handleEmitData = useCallback(
    (data: IActionData) => {
      const result = {
        ...data,
        general: MdrUtilities.convertToApiPayload(data.general, GENERAL),
      };

      result[identifierKey] = MdrUtilities.convertToApiPayload(
        data[identifierKey],
        ALL_SECTIONS_CONFIG[identifierKey],
      ) as never;

      if (identifierKey === "e1" || identifierKey === "e4") {
        data.e1And4 = MdrUtilities.convertToApiPayload(data.e1And4, E1And4);
      }

      onClose(result);
    },
    [identifierKey, onClose],
  );

  return (
    <EditDialogWrapper
      open={open}
      mode={mode}
      loading={loading}
      hasChanges={isDirty}
      disabled={disabled}
      onCancel={() => onClose(null)}
      onSave={handleSubmit(handleEmitData)}
      error={error}
      dialogProps={{
        fullWidth: true,
        maxWidth: "xl",
      }}
      title={
        <Box display="flex" alignItems="center" gap={2}>
          <Box flex={1}>
            {/* Breadcrumbs */}
            <MdrTopicBreadcrumb topicIdentifier={topicIdentifier} />
            {/* Actual title*/}
            <span>
              <FormatTranslation
                i18nKey={mode === "create" ? "create_action" : "edit_action"}
                t={t}
                values={{ title: action?.general.name }}
              />
            </span>
          </Box>
          {/* Divider */}
          <Divider orientation="vertical" flexItem />
          {/* Delete button */}
          {mode === "edit" && (
            <Tooltip title={t("delete_action_tooltip")}>
              <span>
                <IconButton
                  onClick={() => (onDelete && action ? onDelete(action.id) : undefined)}
                  disabled={loading}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Box>
      }
    >
      {/* General Disclosures */}
      <FormProvider {...useFormResult}>
        <QueriesWrapper
          queries={[policiesQuery, dmaCategoriesQuery]}
          loadingOverride={() => <Skeleton variant="rounded" height={56} sx={{ my: 2 }} />}
          build={([policies, dmaCategories]) => (
            <ActionTopicComponent
              topicPrefix="general"
              control={control}
              inputs={ALL_SECTIONS_ORDER["general"]}
              inputsMetaData={ALL_SECTIONS_CONFIG["general"]}
              watch={watch}
              disabled={!!disabled}
              dmaCategories={dmaCategories}
              policies={policies}
            />
          )}
        />
      </FormProvider>
      {/* E1 & E4 common Disclosures */}
      {(identifierKey === "e1" || identifierKey === "e4") && (
        <>
          <Divider sx={{ my: 4 }} />
          <ActionTopicComponent
            sectionTitle={t(`section_titles.esrs_e1_and_e4`)}
            topicPrefix="e1And4"
            control={control}
            inputs={E1And4_ORDER}
            inputsMetaData={E1And4}
            watch={watch}
            disabled={!!disabled}
          />
        </>
      )}
      {/* Topic specific Disclosures */}
      {identifierKey && (
        <>
          <Divider sx={{ my: 4 }} />
          <ActionTopicComponent
            sectionTitle={t(`section_titles.esrs_${identifierKey}`)}
            topicPrefix={identifierKey}
            control={control}
            inputs={ALL_SECTIONS_ORDER[identifierKey]}
            inputsMetaData={ALL_SECTIONS_CONFIG[identifierKey]}
            watch={watch}
            disabled={!!disabled}
          />
        </>
      )}
    </EditDialogWrapper>
  );
};
