import { Box, Button, Typography } from "@mui/material";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { usePoliciesQuery } from "../policies.queries";
import { PoliciesTable } from "./policies-table.component";
import {
  DataEntryObjectInputParameterValueDefinitionForPolicy,
  OptionalDefinition,
} from "@netcero/netcero-common";
import {
  IInputParameterValueMetaDataEsrsTopicIdentifierEnum,
  IPolicy,
} from "@netcero/netcero-core-api-client";
import { QueryWrapper } from "../../common/components/query-wrapper.component";

interface IPoliciesListComponentProps {
  organizationId: string;
  recordingPeriodId: string;
  dataEntryObjectId: string;
  rootDataEntryObjectId: string;
  value: OptionalDefinition<DataEntryObjectInputParameterValueDefinitionForPolicy>;
  onChange: (value: DataEntryObjectInputParameterValueDefinitionForPolicy) => void;
  topicIdentifier: IInputParameterValueMetaDataEsrsTopicIdentifierEnum;
  disabled?: boolean;
}

export const PoliciesListComponent: FC<IPoliciesListComponentProps> = (props) => {
  const policiesQuery = usePoliciesQuery(props.organizationId, props.recordingPeriodId);

  return (
    <QueryWrapper
      centerLoader
      query={policiesQuery}
      build={(policies) => <PoliciesListInternal policies={policies} {...props} />}
    />
  );
};

interface IPoliciesListInternalProps extends IPoliciesListComponentProps {
  policies: IPolicy[];
}

const PoliciesListInternal: FC<IPoliciesListInternalProps> = ({
  organizationId,
  recordingPeriodId,
  dataEntryObjectId,
  rootDataEntryObjectId,
  policies,
  value,
  onChange,
  topicIdentifier,
  disabled,
}) => {
  const { t } = useTranslation("data_entry_object_policy_component");

  const filteredPolicies = useMemo(
    () => policies.filter((policy) => policy.associatedTopics.includes(topicIdentifier)),
    [topicIdentifier, policies],
  );

  if (value === undefined) {
    return (
      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          size="small"
          color="success"
          onClick={() => onChange(true)}
          disabled={disabled}
          sx={{ minWidth: 124 }}
        >
          {t("present_policies")}
        </Button>
        <Button
          variant="contained"
          size="small"
          color="error"
          onClick={() => onChange(false)}
          disabled={disabled}
          sx={{ minWidth: 124 }}
        >
          {t("no_present_policies")}
        </Button>
      </Box>
    );
  }

  return value ? (
    <PoliciesTable
      policies={filteredPolicies}
      topicIdentifier={topicIdentifier}
      organizationId={organizationId}
      recordingPeriodId={recordingPeriodId}
      dataEntryObjectId={dataEntryObjectId}
      rootDataEntryObjectId={rootDataEntryObjectId}
      onChange={onChange}
      disabled={disabled}
    />
  ) : (
    <Box display="flex" alignItems="center" gap={2}>
      <Typography>{t("notice_chosen_to_not_adopt_policies")}</Typography>
      <Button
        variant="outlined"
        size="small"
        color="success"
        onClick={() => onChange(true)}
        disabled={disabled}
        sx={{ minWidth: 124 }}
      >
        {t("button_undo_no_policies_adopted")}
      </Button>
    </Box>
  );
};
